import { MappedImage } from '@features/pg-funnel/services/contentful/types'
import { cloneElement, FunctionComponentElement } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styles from '../index.module.scss'

export type DirectionType = 'slide-left' | 'slide-right'

interface TransitionClassNames {
  enter: string;
  enterActive: string;
  exit: string;
  exitActive: string;
}

interface IProps {
  image: MappedImage;
  direction: DirectionType;
  onClick?: () => void;
  accessibleLabel?: string;
}

export function Slide ({
  image, direction, onClick, accessibleLabel
}: IProps) {
  const getClassNames = (direction: DirectionType) => (
    {
      enter: styles[`${direction}-enter`],
      enterActive: styles[`${direction}-enter-active`],
      exit: styles[`${direction}-exit`],
      exitActive: styles[`${direction}-exit-active`]
    }
  )

  const childFactory = (direction: string) => {
    return (child: FunctionComponentElement<{ classNames: TransitionClassNames }>) => (
      cloneElement(child, {
        classNames: { ...getClassNames(direction as DirectionType) }
      })
    )
  }

  return (
    <TransitionGroup childFactory={childFactory(direction)} aria-roledescription="slide">
      <CSSTransition
        key={image.src}
        timeout={400}
        classNames={getClassNames(direction)}
      >
        <img
          src={image.src}
          alt={image.alt}
          className={styles.image}
          onClick={onClick}
          aria-label={onClick ? accessibleLabel : undefined}
        />
      </CSSTransition>
    </TransitionGroup>
  )
}
