import { Swipeable } from 'src/features/shared/components/swipeable/Swipeable';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import { MappedImage } from '@features/pg-funnel/services/contentful/types';
import { ArrowButton } from '@features/shared/components/arrow-button';
import {Layout} from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import useIntersection from '@utils/hooks/use-intersection';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  Body,
  FontColor,
  H4,
  Large,
} from 'src/features/shared/components/typography';
import styles from './index.module.scss';
import { DirectionType, Slide } from './slide';

interface IImageSliderItem {
  primaryImage: MappedImage;
  secondaryImage: MappedImage;
  description: string;
  title: string;
}

interface IImageSliderProps {
  anchorLinkTitle: string | null;
  items: IImageSliderItem[];
  onClickImage?: () => void;
  accessibleLabel?: string;
  isPanel?: boolean;
}

export function ImageSlider({
  items,
  anchorLinkTitle,
  onClickImage,
  accessibleLabel,
  isPanel,
}: IImageSliderProps) {
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState<DirectionType>('slide-right');
  const activeItem = useMemo(() => items[activeIndex], [activeIndex]);
  const componentRef = useRef<HTMLInputElement>(null);
  const isVisible = useIntersection(componentRef);
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const nextSlide = useCallback(() => {
    const newIndex = items.length - 1 === activeIndex ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
    setDirection('slide-right');
  }, [activeIndex]);

  const prevSlide = useCallback(() => {
    const newIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
    setDirection('slide-left');
  }, [activeIndex]);

  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      if (autoPlayEnabled) nextSlide();
    }, 6000);

    return () => clearInterval(interval);
  }, [isVisible, nextSlide, autoPlayEnabled]);

  return (
    <Section forceMobileView={isPanel}>
      <div
        ref={componentRef}
        onMouseOver={() => setAutoPlayEnabled(false)}
        onFocus={() => setAutoPlayEnabled(false)}
        onMouseOut={() => setAutoPlayEnabled(true)}
        onBlur={() => setAutoPlayEnabled(true)}
      >
        <Anchor title={anchorLinkTitle} />

        <Layout.Container forceMobileView={isPanel}>
          <Layout.Row>
            <Layout.Col columns={isPanel ? 'sm:12' : 'sm:8'}>
              <Swipeable
                onSwipeLeft={prevSlide}
                onSwipeRight={nextSlide}
                className={classNames(styles.imageWrapper, styles.primaryImage)}
                aria-label={`${activeIndex + 1} of ${items.length}`}
              >
                <Slide
                  image={activeItem.primaryImage}
                  direction={direction}
                  onClick={onClickImage}
                  accessibleLabel={accessibleLabel}
                />
              </Swipeable>
            </Layout.Col>
            <Layout.Col columns={isPanel ? 'sm:12' : 'sm:4'}>
              <div className={styles.contentWrapper}>
                <div>
                  {!isMobile && !isPanel && (
                    <div
                      className={classNames(
                        styles.imageWrapper,
                        styles.secondaryImage,
                      )}
                    >
                      <Slide
                        image={activeItem.secondaryImage}
                        direction={direction}
                        onClick={onClickImage}
                        accessibleLabel={accessibleLabel}
                      />
                    </div>
                  )}
                  {items.length > 1 && (
                    <div className={styles.controlWrapper}>
                      <Large>{`${activeIndex + 1} / ${items.length}`}</Large>
                      <div className={styles.buttonsWrapper}>
                        <ArrowButton isPrev onClick={prevSlide} />
                        <ArrowButton onClick={nextSlide} />
                      </div>
                    </div>
                  )}
                </div>
                <TransitionGroup>
                  <CSSTransition
                    key={activeIndex}
                    timeout={1000}
                    classNames={{
                      enter: styles['text-opacity-enter'],
                      enterActive: styles['text-opacity-enter-active'],
                      exit: styles['text-opacity-exit'],
                      exitActive: styles['text-opacity-exit-active'],
                    }}
                  >
                    <div>
                      <H4>{activeItem.title}</H4>
                      <Body color={FontColor.Neutral02}>
                        {activeItem.description}
                      </Body>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </Layout.Col>
          </Layout.Row>
        </Layout.Container>
      </div>
    </Section>
  );
}
