import { ReactNode, ReactNodeArray, TouchEvent, useState } from 'react';

interface Props {
  onSwipeRight: () => void;
  onSwipeLeft: () => void;
  children: ReactNodeArray | ReactNode;
  className?: string;
}

export function Swipeable({ children, onSwipeRight, onSwipeLeft, ...props }: Props) {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  function handleTouchStart(e: TouchEvent) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e: TouchEvent) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 50) {
      onSwipeRight();
    }

    if (touchStart - touchEnd < -50) {
      onSwipeLeft();
    }
  }
  return (
    <div
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={() => handleTouchEnd()}
      {...props}
    >
      {children}
    </div>
  );
}

